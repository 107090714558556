import { SimpleCrypto } from "simple-crypto-js";

export function clear() {
  window.localStorage.clear();
}

export function has(name) {
  if (get(name) === null) {
    return false;
  }
  return true;
}

export function getJsonObject(name) {
  return get(name);
}

export function setJsonObject(name, object) {
  set(name, JSON.stringify(object));
}

export function get(name) {
  if (
    name == "lang" ||
    name == "token" ||
    name == "refresh_token" ||
    name == "lastTimeCheck"
  ) {
    return window.localStorage.getItem(name);
  } else if (name == "user" || name == "menu_states" || name == "croot") {
    if (
      window.localStorage.getItem(name) != null ||
      window.localStorage.getItem(name) != undefined
    ) {
      const des = window.localStorage.getItem(name);
      let _secretKey = "key";
      let simpleCrypto = new SimpleCrypto(_secretKey);
      let decipherText = simpleCrypto.decrypt(des);
      return decipherText;
    } else {
      return window.localStorage.getItem(name);
    }
  }
}

export function set(name, variable) {
  if (
    name == "lang" ||
    name == "token" ||
    name == "refresh_token" ||
    name == "lastTimeCheck"
  ) {
    return window.localStorage.setItem(name, variable);
  } else if (name == "user" || name == "menu_states" || name == "croot") {
    let _secretKey = "key";
    let simpleCrypto = new SimpleCrypto(_secretKey);
    let chiperText = simpleCrypto.encrypt(variable);
    return window.localStorage.setItem(name, chiperText);
  }
}

export function remove(name) {
  window.localStorage.removeItem(name);
}
